import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { DefaultTheme } from 'styled-components'

import Spacer from '../../Spacer'
import RequiredAsterisk from '../RequiredAsterisk'
import * as T from './types'

interface IAlignItemProps {
  alignItems?: string
  disabled?: boolean
}

const CheckboxContainer = styled.div<IAlignItemProps>`
  display: flex;
  align-items: ${props => props.alignItems || 'center'};
  ${props =>
    props.disabled
    && `
    opacity: 0.5;
  `}
`

// NB: pass both width and height props and maintain a 5:4 pixel size ratio
interface IIconProps {
  width?: number
  height?: number
}
const Icon = styled.svg<IIconProps>`
  fill: ${props => props.theme.colors.white};
  width: 10px;
  height: 8px;
  ${props =>
    props.width
    && props.height
    && `
    width: ${props.width}px;
    height: ${props.height}px;
  `}
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface ICheckboxProps {
  checkboxColor?: string
  checkboxMargin?: string
  circle?: boolean
  disabled?: boolean
  type?: 'error' | 'default'
}

interface ICheckboxPropsArg extends T.ICheckedProps, T.ISizeProps, ICheckboxProps {
  theme: DefaultTheme
}

const getBackgroundColor = (props: ICheckboxPropsArg) => {
  if (props.checked) {
    return props.checkboxColor || props.theme.colors.blue
  }

  return props.type === 'error' ? props.theme.colors.red6 : props.theme.colors.white
}

const getBorderColor = (props: ICheckboxPropsArg) => {
  if (props.type === 'error') return props.theme.colors.red5

  if (props.checked) return props.checkboxColor || props.theme.colors.blue

  return props.theme.colors.grey30
}

const StyledCheckbox = styled.div<T.ICheckedProps & T.ISizeProps & ICheckboxProps>`
  outline: none;
  ${props => props?.checkboxMargin || 'margin-left: 3px'};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: ${props => props.size || 18}px;
  height: ${props => props.size || 18}px;
  background: ${props => getBackgroundColor(props)};
  border-radius: ${props => (props.circle ? '100%' : '3px')};
  transition: all 150ms;
  border: 2px solid ${props => getBorderColor(props)};
  ${props =>
    props.type === 'error'
    && `box-shadow: 0px 0px 5px ${props.theme.colors.transparentRedAlpha50};`}

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    ${props =>
    props.checked
      ? 'box-shadow: 0px 4px 4px rgba(7, 191, 241, 0.2);'
      : `border: 2px solid ${props.checkboxColor || props.theme.colors.blue};`}
  }

  ${HiddenCheckbox}:focus + & {
    outline: none;
    border: 2px solid ${props => props.theme.colors.blue};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }

  transition: all 200ms ease-in-out;
`

interface ILabelProps {
  centerLabel?: boolean
  textAlignCenter?: boolean
  titleText?: boolean
  fontSize?: string
  lineHeight?: string
}
const StyledLabel = styled.label<ILabelProps & IAlignItemProps>`
  padding-top: ${props => (props.alignItems === 'flexStart' ? 0 : 3)}px; /* font correction */
  margin-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '15px')};
  line-height: ${props => props.lineHeight ?? '20.5px'};
  color: ${props => props.theme.colors.neutral900};
  ${props => props.centerLabel && 'align-self: center;'}
  display: flex;
  align-items: center;
  ${props => props.textAlignCenter && 'text-align: center;'}

  ${props =>
    props.titleText
    && `
    font-weight: 900;
    padding-top: 3px;
    font-size: ${props.fontSize ? props.fontSize : '15px'};
    line-height: ${props.lineHeight ?? '20px'};
    text-transform: uppercase;
  `}
`

const Emoji = styled.span<ILabelProps>`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '22px')};
`
interface IProps {
  alignItems?: string
  centerLabel?: boolean
  checkboxColor?: string
  checkboxMargin?: string
  checked?: boolean
  circle?: boolean
  textAlignCenter?: boolean
  emoji?: string
  iconProps?: { width: number; height: number }
  label?: string
  labelFontSize?: string
  labelLineHeight?: string
  name: string
  required?: boolean
  size?: number
  titleText?: boolean
  disabled?: boolean
  type?: 'error' | 'default'
  onChange(checked: boolean, name?: string): void
}

// @FIXME - onchange props should be 1. name, 2. checked, to match other input onchange handlers
const CheckboxInput: FunctionComponent<IProps> = ({
  alignItems,
  centerLabel,
  circle,
  textAlignCenter,
  checkboxColor,
  checkboxMargin,
  checked,
  emoji,
  iconProps,
  label,
  labelFontSize,
  labelLineHeight,
  name,
  required,
  size,
  titleText,
  disabled,
  type = 'default',
  onChange,
}) => {
  // this is an optimistic optimization that makes the change feel instant
  // there was lag waiting for the listing change to propagate
  const [checkedValue, setCheckedValue] = useState<boolean>(!!checked)

  useEffect(() => {
    setCheckedValue(!!checked)
  }, [checked])

  const handleClick = () => {
    if (disabled) return

    setCheckedValue(!checkedValue)
    onChange(!checked, name)
  }

  return (
    <CheckboxContainer
      onClick={handleClick}
      alignItems={alignItems}
      disabled={disabled}
    >
      <HiddenCheckbox
        checked={checkedValue}
        readOnly
        data-testid={`${name}Hidden`}
      />
      <StyledCheckbox
        data-testid={name}
        circle={circle}
        checkboxColor={checkboxColor}
        checkboxMargin={checkboxMargin}
        checked={checkedValue}
        defaultChecked={false}
        size={size}
        disabled={disabled}
        type={type}
      >
        <Icon
          viewBox="0 0 10 8"
          {...iconProps}
        >
          <path d="M3.00007 7.34997C3.0908 7.44665 3.21748 7.50149 3.35007 7.50149C3.48265 7.50149 3.60933 7.44665 3.70007 7.34997L9.32007 1.72997C9.41472 1.63609 9.46797 1.50829 9.46797 1.37497C9.46797 1.24166 9.41472 1.11386 9.32007 1.01997L8.79007 0.489974C8.59564 0.299403 8.28449 0.299403 8.09007 0.489974L3.35007 5.22997L1.85007 3.73997C1.75933 3.6433 1.63265 3.58846 1.50007 3.58846C1.36748 3.58846 1.2408 3.6433 1.15007 3.73997L0.620067 4.26997C0.525411 4.36386 0.472168 4.49166 0.472168 4.62497C0.472168 4.75829 0.525411 4.88609 0.620067 4.97997L3.00007 7.34997Z" />
        </Icon>
      </StyledCheckbox>
      {required && <RequiredAsterisk />}
      {label && (
        <StyledLabel
          htmlFor={name}
          alignItems={alignItems}
          centerLabel={centerLabel}
          titleText={titleText}
          fontSize={labelFontSize}
          lineHeight={labelLineHeight}
          textAlignCenter={textAlignCenter}
        >
          {label}
          {emoji && (
            <>
              <Spacer size={8} />
              <Emoji>{` ${emoji}`}</Emoji>
            </>
          )}
        </StyledLabel>
      )}
    </CheckboxContainer>
  )
}

export default CheckboxInput
