import styled from 'styled-components'

const RequiredAsterisk = styled.span`
  color: ${props => props.theme.colors.red};
  margin-left: 4px;
  font-size: 14px;
  ::before {
    content: '*';
  }
`

export default RequiredAsterisk
