import {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import MaskedInput from 'react-text-mask'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import DeleteIcon from 'components/SVG/DeleteXCircle'

import { EventHandlerContext } from 'services/contexts'

import { removeCurrencyFormatting, removeHyphens, removeUnderscores } from 'utils/stringFormatting'
import useDebounce from 'utils/useDebounce'

import * as T from 'types'

import CheckboxInput from './CheckboxInput'

const DISALLOWED_DECIMAL_INPUT_CHARS = ['-', '+', 'e', ' ']
const DISALLOWED_NUMBER_INPUT_CHARS = [...DISALLOWED_DECIMAL_INPUT_CHARS, '.']

const noop = () => {}

interface IAccentColorProps {
  accentColor?: string
}
interface IBackgroundProps {
  background?: string
}
interface IBorderColorProps {
  borderColor?: string
}
interface IBorderRadiusProps {
  borderRadius?: string
}
interface ICursorProps {
  cursor?: string
}
interface IDisabledProps {
  disabled?: boolean
}
interface IErrorProps {
  error?: boolean
}
interface ILargeTextProps {
  largeText?: boolean
}
interface INoBorderProps {
  noBorder?: boolean
}
interface IPlaceholderTextColorProps {
  placeholderTextColor?: string
}
interface IRequiredMissingFieldProps {
  requiredMissingField?: boolean
}
interface ITextColorProps {
  textColor?: string
}
interface ITextPaddingProps {
  padding?: string
}
interface ITextAlignProps {
  textAlign?: 'center' | 'left' | 'right'
}
interface IUppercaseProps {
  uppercase?: boolean
}
interface IWidthProps {
  inputWidth?: string
}

const currencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const wholeNumberMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

interface IHeightProps {
  fullHeight?: boolean
  inputHeight?: number
}
interface IFlexProps {
  flexBasis?: string
}
const RelativeContainer = styled.div<IHeightProps & IFlexProps>`
  position: relative;
  width: 100%;
  ${props => props.flexBasis && `flex-basis: ${props.flexBasis}`}
  ${props => props.fullHeight && 'height: 100%;'}
`

const AbsoluteContainer = styled.div<IDisabledProps & IBackgroundProps & IAccentColorProps>`
  position: absolute;
  top: 2px;
  cursor: pointer;
  right: 1px;
  height: calc(100% - 4px);
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  & > svg path {
    ${props => props.disabled && `fill: ${props.theme.colors.grey3};`}
  }
`

const FilledInfoIconContainer = styled(AbsoluteContainer)`
  top: 0;
  right: 0;
  height: 100%;
  width: 30px;
  border: 1px solid ${props => props.theme.colors.grey3};
  background: ${props => props.theme.colors.grey3};
`

interface IInputPropsInterface
  extends IAccentColorProps,
    IBackgroundProps,
    IBorderColorProps,
    IBorderRadiusProps,
    ICursorProps,
    IDisabledProps,
    IErrorProps,
    IHeightProps,
    ILargeTextProps,
    INoBorderProps,
    IPlaceholderTextColorProps,
    IRequiredMissingFieldProps,
    ITextColorProps,
    ITextAlignProps,
    IUppercaseProps,
    IWidthProps,
    ITextPaddingProps {}

const StyledInput = styled.input<IInputPropsInterface>`
  padding-left: 10px;
  padding-top: 4px; /* font correction */
  ${props => props.padding && `padding: ${props.padding};`}
  background: ${props => props.background || props.theme.colors.transparentGrey30Alpha50};
  ${props => props.error && `background: ${props.theme.colors.transparentRedAlpha10};`}
  border: ${props => `${props.error ? 2 : 1}px solid ${
    props.error ? props.theme.colors.red : props.borderColor || props.theme.colors.grey30
  }
  `};
  border-radius: ${props => props.borderRadius || '4px'};
  ${props =>
    props.requiredMissingField
    && `
    border: 1px solid ${props.theme.colors.red};
    box-shadow: 0px 0px 4px rgba(231, 58, 62, 0.5);
    background: ${props.theme.colors.red50};
  `}
  ${props => props.noBorder && 'border: none;'}
  box-sizing: border-box;
  width: ${props => props.inputWidth || '100%'};
  height: ${props => (props.fullHeight ? '100%' : '36px')};
  ${props => props.inputHeight && `height: ${props.inputHeight}px;`}

  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 20px;

  caret-color: ${props => props.accentColor || props.theme.colors.teal500};
  color: ${props => props.textColor || props.theme.colors.neutral900};
  ${props => props.textAlign && `text-align: ${props.textAlign};`}

  ${props =>
    props.largeText
    && `
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
  `}

  /* uppercase prop denotes a set of uppercase styles */
  ${props =>
    props.uppercase
    && `
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.28571px;
    text-transform: uppercase;
  `}

  &::placeholder {
    color: ${props => props.placeholderTextColor || props.theme.colors.grey26};
    ${props => props.disabled && `color: ${props.theme.colors.grey3};`}
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: ${props => props.placeholderTextColor || props.theme.colors.grey26};
    ${props => props.disabled && `color: ${props.theme.colors.grey3};`}
    opacity: 1;
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid ${props => props.accentColor || props.theme.colors.blue5};

    & ~ ${AbsoluteContainer} {
      right: 2px;
      width: 37px;
    }

    & ~ ${FilledInfoIconContainer} {
      border: none;
      height: calc(100% - 4px);
      top: 2px;
      width: 28px;
      padding-left: 2px;
      border-radius: 0 2px 2px 0;
    }
  }

  cursor: ${props => (props.disabled ? 'not-allowed' : props.cursor || 'auto')};
`

const StyledNumberInput = styled(StyledInput).attrs({ type: 'number' })`
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const Error = styled.div`
  position: absolute;
  bottom: -26px;
  color: ${props => props.theme.colors.red};
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
`

const Spacer = styled.div`
  height: 7px;
  width: 100%;
`

interface IOnChange {
  onChange(field: string, value: string | boolean | number | null): void
  onChangeImmediate(field: string, value: string | boolean | number | null): void
}

export type ITextInputProps = T.RequireAtLeastOne<IOnChange> & {
  accentColor?: string
  autoComplete?: string
  background?: string
  borderColor?: string
  borderRadius?: string
  checkbox?: boolean
  checkboxLabel?: string
  checkboxChecked?: boolean
  clearOnClick?: boolean
  controlled?: boolean
  cursor?: string
  dataTestId?: string
  delay?: number
  disabled?: boolean
  disableNewValues?: boolean
  flexBasis?: string
  fullHeight?: boolean
  hasError?: boolean
  inputHeight?: number
  inputWidth?: string
  isCurrency?: boolean
  isNumber?: boolean
  isDecimal?: boolean
  isPhoneNumber?: boolean
  isWholeNumber?: boolean
  largeText?: boolean
  minValue?: number
  maxValue?: number
  name: string
  noBorder?: boolean
  padding?: string
  placeholder?: string
  placeholderTextColor?: string
  requiredMissingField?: boolean
  shouldDebounce?: boolean
  submissionError?: boolean
  textAlign?: 'center' | 'left' | 'right'
  textColor?: string
  type?: string
  uppercase?: boolean
  value?: string | number | null
  onBlur?(): void
  onCheckboxChange?(key: string, value: boolean): void
  onFocus?(): void
  renderIcon?(): ReactNode | null
  renderFilledInfoIcon?: () => ReactNode | null
}

const TextInput: FunctionComponent<ITextInputProps> = ({
  accentColor,
  autoComplete,
  background,
  borderColor,
  borderRadius,
  checkbox,
  checkboxLabel,
  checkboxChecked,
  clearOnClick,
  controlled,
  cursor,
  dataTestId,
  delay,
  disabled,
  disableNewValues,
  flexBasis,
  fullHeight,
  hasError,
  inputHeight,
  inputWidth,
  isCurrency,
  isNumber,
  isDecimal,
  isPhoneNumber,
  isWholeNumber,
  largeText,
  minValue,
  maxValue,
  name,
  noBorder,
  padding,
  placeholder,
  placeholderTextColor,
  requiredMissingField,
  shouldDebounce = true,
  submissionError,
  textAlign,
  textColor,
  type,
  uppercase,
  value,
  onBlur,
  onChange = noop,
  onChangeImmediate = noop,
  onCheckboxChange,
  onFocus,
  renderIcon,
  renderFilledInfoIcon,
}) => {
  const valueStr = value === undefined || value === null ? '' : String(value)
  const node = useRef<HTMLInputElement>(null)

  const handleClickIcon = () => {
    if (node && node.current) {
      node.current.focus()
      if (onFocus) onFocus()
    }
  }

  const theme = useContext(ThemeContext)

  // we can sometimes immediately set the error internal to the textInput given a conflict with some
  // other prop(s) (e.g. maxValue); other times we display an error at the point of submission,
  // this is passed as the `submissionError` boolean prop
  const [error, setError] = useState('')

  // text input focus event status
  const { inputOnFocusEvent } = useContext(EventHandlerContext)

  const handleFocus = (event: FocusEvent) => {
    if (onFocus) onFocus()

    event.persist() /* https://reactjs.org/docs/legacy-event-pooling.html */
    inputOnFocusEvent(event)
  }

  const handleBlur = () => {
    inputOnFocusEvent(false)
    if (onBlur) onBlur()
  }

  // checkbox status
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (checkboxChecked) setChecked(checkboxChecked)
  }, [checkboxChecked])

  const handleToggleCheckbox = (checkedValue: boolean) => {
    setChecked(checkedValue)
    if (onCheckboxChange) {
      onCheckboxChange(name, checkedValue)
    }

    onChange(name, checkedValue)
  }

  const [newValue, setNewValue] = useState(valueStr)

  useEffect(() => {
    setNewValue(valueStr)
  }, [valueStr])

  const formatChangeVal = (val: string) =>
    isCurrency ? removeCurrencyFormatting(String(val)) : val

  const callOnChange = (debouncedVal: string) => {
    if (error) return

    const prevChangeVal = formatChangeVal(valueStr)
    const changeVal = formatChangeVal(debouncedVal)

    if (prevChangeVal !== changeVal) onChange(name, changeVal)
  }

  useDebounce(newValue, delay ?? 1000, {
    enabled: shouldDebounce,
    fn: callOnChange,
  })

  const processValue = (val: string) => {
    let newVal = val

    if (newVal === '') {
      return setNewValue(newVal)
    }

    if (isNumber || isCurrency) {
      const number = isCurrency ? removeCurrencyFormatting(String(newVal)) : newVal
      if (Number.isNaN(Number(number))) return

      if (minValue && Number(number) < minValue) {
        setError(`Value has to be over ${minValue}`)
      } else if (isNumber && (!minValue || minValue < 0) && Number(number) < 0) {
        setError('Value cannot be negative')
      } else if (maxValue && Number(number) > maxValue) {
        setError(`Value has to be under ${maxValue}`)
      } else {
        setError('')
      }
    }

    if (isPhoneNumber) newVal = removeUnderscores(removeHyphens(newVal))

    setNewValue(newVal)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event?.target.value

    // do any work that shouldn't be debounced here
    onChangeImmediate(name, val)

    // some inputs are focusable but text cannot be changed via typing
    if (disableNewValues) return

    processValue(val)
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (isDecimal) {
      if (DISALLOWED_DECIMAL_INPUT_CHARS.includes(event.key)) event.preventDefault()

      if (String(value).split('.')?.[1]?.length >= 2) event.preventDefault()

      return
    }

    if (isNumber && DISALLOWED_NUMBER_INPUT_CHARS.includes(event.key)) event.preventDefault()
  }

  const handleDelete = () => {
    if (node && node.current) {
      node.current.value = ''
    }

    onChange(name, '')
  }

  // @TODO - We should move masked input into a MaskedTextInputs dir instead of writing it directly
  // in this file. Currency inputs should be converted to a maksed input

  const currencyMask = createNumberMask(currencyMaskOptions)
  const wholeNumberMask = createNumberMask(wholeNumberMaskOptions)

  let mask = phoneMask

  if (isCurrency) {
    mask = currencyMask
  } else if (isWholeNumber) {
    mask = wholeNumberMask
  }

  const valueObj = controlled ? { value: newValue } : { defaultValue: newValue }

  let Input: StyledComponent<'input', any, any> = StyledInput
  if (isNumber && !isWholeNumber) Input = StyledNumberInput

  const boundValues: { min?: number; max?: number } = isNumber ? { min: minValue || 0 } : {}
  if (maxValue) boundValues.max = maxValue

  return (
    <RelativeContainer
      fullHeight={fullHeight}
      flexBasis={flexBasis}
    >
      {isPhoneNumber || isCurrency || isWholeNumber
        ? (
          <MaskedInput
            mask={mask}
            onChange={handleChange}
            onBlur={handleBlur}
            {...valueObj}
            render={(ref, props) => (
              <Input
                data-testid={dataTestId ?? name}
                accentColor={accentColor}
                autoComplete={autoComplete}
                background={background}
                borderColor={borderColor}
                borderRadius={borderRadius}
                cursor={cursor}
                disabled={checked || disabled}
                error={!!error || submissionError}
                fullHeight={fullHeight}
                id={name}
                inputHeight={inputHeight}
                inputWidth={inputWidth}
                largeText={largeText}
                name={name}
                noBorder={noBorder}
                padding={padding}
                placeholder={placeholder || ''}
                placeholderTextColor={placeholderTextColor}
                ref={ref}
                requiredMissingField={requiredMissingField}
                textAlign={textAlign}
                textColor={textColor}
                type={type || 'text'}
                uppercase={uppercase}
                {...boundValues}
                {...props}
                onFocus={handleFocus}
              />
            )}
          />
        )
        : (
          <Input
            data-testid={dataTestId ?? name}
            accentColor={accentColor}
            autoComplete={autoComplete}
            background={background}
            borderColor={borderColor}
            borderRadius={borderRadius}
            cursor={cursor}
            disabled={checked || disabled}
            error={!!error || submissionError}
            fullHeight={fullHeight}
            id={name}
            inputHeight={inputHeight}
            inputWidth={inputWidth}
            largeText={largeText}
            name={name}
            noBorder={noBorder}
            padding={padding}
            placeholder={placeholder || ''}
            placeholderTextColor={placeholderTextColor}
            ref={node}
            requiredMissingField={requiredMissingField}
            textColor={textColor}
            type={type || 'text'}
            uppercase={uppercase}
            {...valueObj}
            {...boundValues}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            onFocus={handleFocus}
          />
        )}
      {checkbox && <Spacer />}
      {checkbox && (
        <CheckboxInput
          name={name}
          label={checkboxLabel}
          onChange={handleToggleCheckbox}
          checked={checked}
        />
      )}
      {renderIcon && (
        <AbsoluteContainer
          accentColor={accentColor}
          background={background}
          disabled={disabled}
          onClick={handleClickIcon}
        >
          {renderIcon()}
        </AbsoluteContainer>
      )}
      {renderFilledInfoIcon && (
        <FilledInfoIconContainer>{renderFilledInfoIcon()}</FilledInfoIconContainer>
      )}
      {clearOnClick && (
        <AbsoluteContainer onClick={handleDelete}>
          <DeleteIcon
            width={20}
            height={20}
            fill={hasError ? theme.colors.red : theme.colors.grey3}
          />
        </AbsoluteContainer>
      )}
      {/* Missing field label is displayed along with current error style
        but its text should not be set as an error message as it will prevent updating the input value */}
      {(error !== '' && <Error>{error}</Error>)
        || (requiredMissingField && <Error>Answer Required</Error>)}
    </RelativeContainer>
  )
}

export default TextInput
